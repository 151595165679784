<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1">Asetukset</div>

    <v-row dense>
      <v-col>
        <v-btn-toggle mandatory color="blue" v-model="tab">
          <v-btn value="0"> Tili </v-btn>
          <v-btn v-if="isAuthorized('account', 'publicSettings')" value="1">
            Julkiset asetukset
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <div v-if="tab == 0">
      <!-- ACCOUNT -->
      <v-card>
        <div @click="showAccountSettings = !showAccountSettings" :style="getCardStyle()">
          <v-card-title>Tili ja vuokranantaja</v-card-title>
          <v-icon class="mr-2">{{
            !showAccountSettings ? "mdi-chevron-down" : "mdi-chevron-up"
          }}</v-icon>
        </div>

        <Account ref="account" v-show="showAccountSettings"></Account>
      </v-card>

      <!-- CONTRACT -->
      <v-card class="mt-2">
        <div @click="showContractSettings = !showContractSettings" :style="getCardStyle()">
          <v-card-title>Vuokrasopimus</v-card-title>
          <v-icon class="mr-2">{{
            !showTenantSettings ? "mdi-chevron-down" : "mdi-chevron-up"
          }}</v-icon>
        </div>

        <Contract v-show="showContractSettings"></Contract>
      </v-card>

      <!-- EMAIL -->
      <v-card class="mt-2">
        <div @click="showEmailSettings = !showEmailSettings" :style="getCardStyle()">
          <v-card-title>Sähköpostit</v-card-title>
          <v-icon class="mr-2">{{
            !showEmailSettings ? "mdi-chevron-down" : "mdi-chevron-up"
          }}</v-icon>
        </div>

        <EmailNotifications ref="emails" v-show="showEmailSettings"></EmailNotifications>
      </v-card>

      <!-- INVOICE -->
      <v-card class="mt-2">
        <div @click="showInvoiceSettings = !showInvoiceSettings" :style="getCardStyle()">
          <v-card-title>Laskutus</v-card-title>
          <v-icon class="mr-2">{{
            !showInvoiceSettings ? "mdi-chevron-down" : "mdi-chevron-up"
          }}</v-icon>
        </div>

        <Invoice ref="invoice" v-show="showInvoiceSettings"></Invoice>
      </v-card>

      <!-- INVOICE INTEGRATION-->
      <v-card class="mt-2">
        <div
          @click="showInvoiceIntegrationSettings = !showInvoiceIntegrationSettings"
          :style="getCardStyle()"
        >
          <v-card-title>Laskutusintegraatio</v-card-title>
          <v-icon class="mr-2">{{
            !showInvoiceIntegrationSettings ? "mdi-chevron-down" : "mdi-chevron-up"
          }}</v-icon>
        </div>

        <integration-settings v-show="showInvoiceIntegrationSettings"></integration-settings>
      </v-card>

      <!-- TENANT -->
      <v-card class="mt-2">
        <div @click="showTenantSettings = !showTenantSettings" :style="getCardStyle()">
          <v-card-title>Vuokralaiset</v-card-title>
          <v-icon class="mr-2">{{
            !showTenantSettings ? "mdi-chevron-down" : "mdi-chevron-up"
          }}</v-icon>
        </div>

        <Tenant v-show="showTenantSettings"></Tenant>
      </v-card>

      <!-- DEPOSIT -->
      <v-card class="mt-2" v-if="isAuthorized('realtor', 'create')">
        <div @click="showDepositSettings = !showDepositSettings" :style="getCardStyle()">
          <v-card-title>Vakuudet</v-card-title>
          <v-icon class="mr-2">{{
            !showDepositSettings ? "mdi-chevron-down" : "mdi-chevron-up"
          }}</v-icon>
        </div>

        <Deposit v-show="showDepositSettings"></Deposit>
      </v-card>

      <!-- SIGNATURE -->
      <v-card class="mt-2">
        <div @click="showSignheroSettings = !showSignheroSettings" :style="getCardStyle()">
          <v-card-title>Sähköinen allekirjoitus</v-card-title>
          <v-icon class="mr-2">{{
            !showSignheroSettings ? "mdi-chevron-down" : "mdi-chevron-up"
          }}</v-icon>
        </div>

        <Signhero v-show="showSignheroSettings"></Signhero>
      </v-card>

      <!-- OPEN BANKING -->
      <v-card v-if="isAuthorized('openBanking', 'create')" class="mt-2">
        <div @click="showOpenBankingSettings = !showOpenBankingSettings" :style="getCardStyle()">
          <v-card-title>Open banking</v-card-title>
          <v-icon class="mr-2">{{
            !showOpenBankingSettings ? "mdi-chevron-down" : "mdi-chevron-up"
          }}</v-icon>
        </div>
        <v-card v-show="showOpenBankingSettings">
          <v-card-text>
            <v-container>
              <v-btn color="primary" @click="$router.push('/open-banking/nordigen').catch(() => {})"
                >Siirry Nordigen valtuutussivulle</v-btn
              >
              <h3 class="mt-1" :class="nordigenAuthorized ? 'success--text' : 'warning--text'">
                {{ nordigenAuthorized ? "Valtuutus tehty" : "Valtuutusta ei ole vielä tehty" }}
              </h3>
            </v-container>
          </v-card-text>
        </v-card>
      </v-card>

      <!-- STRIPE -->
      <!-- <v-card class="mt-2">
        <div @click="showStripeSettings = !showStripeSettings" :style="getCardStyle()">
          <v-card-title>Stripe</v-card-title>
          <v-icon class="mr-2">{{
            !showStripeSettings ? "mdi-chevron-down" : "mdi-chevron-up"
          }}</v-icon>
        </div>

        <Stripe v-show="showStripeSettings"></Stripe>
      </v-card> -->

      <!-- TOLOTECH -->
      <!-- <v-card class="mt-2">
        <div
          @click="showAccessControlSettings = !showAccessControlSettings"
          :style="getCardStyle()"
        >
          <v-card-title>Kulunhallinta</v-card-title>
          <v-icon class="mr-2">{{
            !showAccessControlSettings ? "mdi-chevron-down" : "mdi-chevron-up"
          }}</v-icon>
        </div>

      </v-card> -->

      <v-btn color="info" class="mr-4 mt-5" @click="save"> Tallenna asetukset</v-btn>
    </div>

    <!-- Public settings -->
    <public-settings v-if="tab == 1"></public-settings>
  </div>
</template>

<script>
import Account from "../../components/Settings/Account.vue";
import Contract from "../../components/Settings/Contract.vue";
import EmailNotifications from "../../components/Settings/EmailNotifications.vue";
import Invoice from "../../components/Settings/Invoice.vue";
import Tenant from "../../components/Settings/Tenant.vue";
import Deposit from "../../components/Settings/Deposit.vue";
import IntegrationSettings from "../../components/Settings/IntegrationSettings.vue";
import Signhero from "../../components/Settings/Signhero.vue";
import PublicSettings from "./PublicSettings.vue";
import mixins from "../../mixins/mixins";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  title: "Asetukset",

  components: {
    PublicSettings,
    Account,
    Contract,
    Invoice,
    Tenant,
    Deposit,
    IntegrationSettings,
    Signhero,
    EmailNotifications,
    // Stripe,
  },

  mixins: [mixins],

  data: () => {
    return {
      tab: 0,
      showAccountSettings: true,
      showEmailSettings: false,
      showTenantSettings: false,
      showDepositSettings: false,
      showContractSettings: false,
      showInvoiceSettings: false,
      showInvoiceIntegrationSettings: false,
      showStripeSettings: false,
      showSignheroSettings: false,
      showAccessControlSettings: false,
      showOpenBankingSettings: false,
    };
  },

  computed: {
    ...mapState("account", ["currentUser"]),

    nordigenAuthorized() {
      return this.currentUser.currentAccount?.settings?.openBanking?.nordigen?.authorized;
    },
  },

  watch: {
    currentUser(val) {
      this.setAccountData(val.currentAccount);
    },
  },

  async created() {
    this.setAccountData(this.currentUser.currentAccount);

    // SignHero automation
    if (this.$route.query.code && this.$route.query.state == "vuokranet") {
      try {
        await this.saveAccessToken(this.$route.query.code);
        await this.getCurrentUser();
        this.$router.push("/settings");
      } catch (err) {
        this.showPopup(err, "error");
      }
    }
  },

  methods: {
    ...mapActions("account", ["getCurrentUser"]),
    ...mapMutations("settings", ["setAccountData"]),
    ...mapActions("settings", ["updateAccount", "saveAccessToken"]),

    async save() {
      if (
        this.$refs.account.validate() &&
        this.$refs.invoice.confirmCostAccountSettings() &&
        this.$refs.emails.validate()
      ) {
        try {
          await this.updateAccount();
          await this.getCurrentUser();
          this;
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },

    getCardStyle() {
      return "display: flex;align-items: center;justify-content: space-between;cursor: pointer;";
    },
  },
};
</script>

<style scoped></style>
